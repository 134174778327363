.page2-pptx2video-audio-script-page {
    width: 100vw !important;
    height: 100vh !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    align-self: stretch !important;
}

.page2-content {
    display: flex !important;
    align-items: flex-start !important;
    flex: 1 0 0 !important;
    align-self: stretch !important;
    max-height: calc(100% - 80px) !important;
}

.page2-left-side {
    display: flex !important;
    width: 45% !important;
    padding: 16px !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 30px !important;
    align-self: stretch !important;
    background: var(--white, #FFF) !important;
}

.page2-footer {
    display: flex !important;
    padding: 20px 30px 20px 15px !important;
    justify-content: flex-end !important;
    align-items: center !important;
    gap: 10px !important;
    align-self: stretch !important;
    background: #2C7A7B !important;
}

.page2-zurucksetzen {
    display: flex !important;
    padding-left: 10px !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 10px !important;
    flex: 1 0 0 !important;
}

.page2-focontent {
    display: flex !important;
    align-items: center !important;
    gap: 20px !important;
}

.page2-zuruck-button {
    display: flex !important;
    height: 40px !important;
    padding: 0px 16px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 8px !important;
    border-radius: 8px !important;
    background: #319795 !important;
    color: var(--Colors-Base-white, #FFF) !important;
}

.page2-zuruck-button:hover {
    cursor: pointer !important;
    opacity: 0.9 !important;
}

.page2-zuruck-button-left {
    width: 16px !important;
    height: 16px !important;
}

.page2-zuruck-button-text {
    color: var(--Colors-Base-white, #FFF) !important;

    /* md/semibold */
    font-family: "Open Sans" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    /* 150% */
}

.page2-focontent {
    display: flex !important;
    align-items: center !important;
    gap: 20px !important;
}


.page2-pos-text {
    display: flex !important;
    align-items: center !important;
    gap: 15px !important;
}


.page2-time {
    display: flex !important;
    align-items: center !important;
    gap: 5px !important;
}


.page2-pos-icon {
    display: flex !important;
    padding: 0px 8px !important;
    align-items: center !important;
    gap: 10px !important;
    color: var(--Colors-Base-white, #FFF) !important;
}


.page2-time-icon {
    width: 24px !important;
    height: 24px !important;
}


.page2-time-time {
    color: var(--Colors-Base-white, #FFF) !important;

    /* lg/medium */
    font-family: "Open Sans" !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 28px !important;
    /* 155.556% */
}


.page2-video-button {
    display: flex !important;
    width: 210px !important;
    height: 40px !important;
    padding: 0px 16px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 8px !important;
    border-radius: 8px !important;
    background: #319795 !important;
}

.page2-video-button:hover {
    cursor: pointer !important;
    opacity: 0.9 !important;
}

.page2-create-video {
    color: var(--Colors-Base-white, #FFF) !important;

    /* md/semibold */
    font-family: "Open Sans" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    /* 150% */
}

.page2-pos-icon-save {
    display: flex !important;
    padding: 0px 8px !important;
    align-items: center !important;
    gap: 10px !important;
    color: var(--Colors-Base-white, #FFF) !important;
}

.page2-pos-icon-save:hover {
    cursor: pointer !important;
    opacity: 0.9 !important;
}

.page2-icon-save {
    width: 24px !important;
    height: 24px !important;
}

.page2-folienminiaturen {
    display: flex !important;
    padding: 16px 0px 10px 10px !important;
    align-items: flex-start !important;
    align-self: stretch !important;
    border-right: 0.2px solid #000 !important;
    background: var(--white, #FFF) !important;
    box-shadow: 2px 0px 8px 0px rgba(0, 0, 0, 0.25) !important;
    width: 20% !important;
    overflow-y: scroll !important;
}

.page2-folienminiaturen-content {
    display: flex !important;
    flex-direction: column !important;
    gap: 24px !important;
    min-width: calc(100% - 20px) !important;
    min-height: 1012px !important;
}

.page2-zuruck-top-button {
    display: flex !important;
    // width: 320px !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 32px !important;
}

.page2-zuruck-top-button:hover {
    cursor: pointer !important;
    opacity: 0.9 !important;
}

.page2-zuruck-top-button-group {
    display: flex !important;
    align-items: center !important;
    align-self: stretch !important;
}

.page2-zuruck-top-button-group-input {
    display: flex !important;
    height: 40px !important;
    padding: 0px 8px 0px 18px !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 10px !important;
    flex: 1 0 0 !important;
    border: 2px solid rgba(0, 0, 0, 0.00) !important;
}

.page2-zuruck-top-button-group-frame4 {
    display: flex !important;
    align-items: center !important;
    gap: 14px !important;
    align-self: stretch !important;
}

.page2-zuruck-top-button-group-frame4-text {
    color: #4A5568 !important;
    font-family: "Open Sans" !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    /* 120% */
}

.page2-zuruck-top-button-group-inputleftelement {
    display: flex !important;
    width: 30px !important;
    height: 40px !important;
    padding-left: 10px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 10px !important;
}

.page2-zuruck-top-button-group-inputleftelement-icon {
    width: 30px !important;
    height: 30px !important;
    flex-shrink: 0 !important;
}

.page2-slide-items {
    display: inline-flex !important;
    min-height: 948px !important;
    padding: 2px 2px 0px 2px !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 15px !important;
    flex-shrink: 0 !important;
}

.page2-slide-item-close {
    display: flex !important;
    align-items: flex-start !important;
    gap: 5px !important;
    align-self: stretch !important;
    border-radius: 8px !important;
}

.page2-slide-item-close:hover {
    cursor: pointer !important;
    opacity: 0.9 !important;
}

.page2-slide-item-close-no {
    display: flex !important;
    min-width: 36px !important;
    padding: 0px 2px !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 10px !important;
}

.page2-slide-item-close-text {
    align-self: stretch !important;
    color: #000 !important;
    text-align: center !important;

    /* md/normal */
    font-family: "Open Sans" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    min-width: 32px !important;
    /* 150% */
}

.page2-slide-item-close-frame-hover {
    display: flex !important;
    width: 80% !important;
    padding: 5px !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 10px !important;
}

.page2-slide-item-close-frame-image {
    // min-height: 129px !important;
    align-self: stretch !important;
    border-radius: 8px !important;
    border: 0.5px solid var(--gray-600, #4A5568) !important;
    background: lightgray 50% / cover no-repeat;
}

.page2-slide-item-close-frame-image {
    aspect-ratio: 1.78 !important;
    // padding: 0px 2px !important;
}

.page2-slide-item-close-arrow-unfold {
    display: flex !important;
    width: 24px !important;
    padding: 110px 0px 2px 0px !important;
    align-items: flex-start !important;
    align-self: stretch !important;
}

.page2-slide-item-open {
    display: flex !important;
    // padding: 5px !important;
    width: 100% !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    border-radius: 8px !important;
    background: var(--blackAlpha-100, rgba(0, 0, 0, 0.06)) !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.page2-slide-item-open-pos-content {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-end !important;
    width: 100% !important;
    padding: 0 5px !important;
}

.page2-slide-item-open-main-folie {
    display: flex !important;
    padding: 5px 0px !important;
    align-items: flex-start !important;
    gap: 5px !important;
    border-radius: 8px !important;
    width: 100% !important;
}

.page2-slide-item-open-main-folie-no {
    display: flex !important;
    min-width: 36px !important;
    padding: 0px 2px !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 10px !important;
}

.page2-slide-item-open-main-folie-no-text {
    align-self: stretch !important;
    color: #000 !important;
    text-align: center !important;

    /* md/normal */
    font-family: "Open Sans" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    min-width: 32px !important;
    /* 150% */
}

.page2-slide-item-open-main-folie-framehover {
    display: flex !important;
    width: 80% !important;
    padding: 5px !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 10px !important;
    border-radius: 8px !important;
}

.page2-slide-item-open-main-folie-framehover-image {
    width: 100% !important;
    height: 100% !important;
    align-self: stretch !important;
    border-radius: 8px !important;
    border: 0.5px solid var(--gray-600, #4A5568) !important;
    background: lightgray 50% / cover no-repeat;
}

.page2-slide-item-open-main-folie-framehover {
    aspect-ratio: 1.78 !important;
    // padding: 0px 2px !important;
}

.page2-slide-item-open-main-folie-arrowunfold {
    display: flex !important;
    // height: 100% !important;
    padding: 110px 0px 2px 0px !important;
    width: 24px !important;
    align-items: flex-end !important;
    align-self: stretch !important;
}

.page2-slide-item-open-main-folie-arrowunfold-icon {
    width: 24px !important;
    height: 24px !important;
    // transform: translateX(-6px) !important;
}

.page2-slide-item-open-siblingItems {
    display: flex !important;
    // padding-right: 12% !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    width: calc(100% - 32px) !important;
}

.page2-slide-item-open-siblingItem-media-pos {
    width: 16% !important;
    display: flex !important;
    flex-direction: column !important;
    padding-left: 5px !important;
    height: 100% !important;
    flex-grow: 1 !important;
}

.page2-slide-item-open-siblingItem-media {
    // width: 16% !important;
    display: flex !important;
    flex-direction: column !important;
    // padding-left: 5px !important;
    height: 100% !important;
    overflow-y: scroll !important;
    // flex-grow: 1 !important;
}

.page2-slide-item-open-siblingItem-media:hover {
    color: #2C7A7B;
}

.page2-slide-item-open-siblingItem {
    display: flex !important;
    padding: 5px !important;
    padding-right: 0px !important;
    align-items: flex-start !important;
    align-self: stretch !important;
    border-radius: 8px !important;
    width: 100% !important;
}

.page2-slide-item-open-siblingItem:hover {
    cursor: pointer !important;
    opacity: 0.9 !important;
}

.page2-slide-item-open-siblingItem-no {
    display: flex !important;
    min-width: 36px !important;
    padding: 0px 2px !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: flex-end !important;
    gap: 10px !important;
    // overflow-x: visible !important;
}

.page2-slide-item-open-siblingItem-no-text {
    align-self: stretch !important;
    color: #000 !important;
    text-align: center !important;

    /* md/normal */
    font-family: "Open Sans" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    min-width: 32px !important;
    white-space: nowrap !important;
    text-align: left !important;
    direction: rtl !important;
    /* 150% */
}

.page2-slide-item-open-siblingItem-frame49-selected {
    display: flex !important;
    width: 100% !important;
    padding: 5px !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 10px !important;
    border-radius: 8px !important;
    background: var(--blackAlpha-500, rgba(0, 0, 0, 0.36)) !important;
}

.page2-slide-item-open-siblingItem-frame49-selected {
    aspect-ratio: 1.78 !important;
    // padding: 0px 2px !important;
}

.page2-slide-item-open-siblingItem-frame49 {
    display: flex !important;
    width: 100% !important;
    padding: 5px !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 10px !important;
    border-radius: 8px !important;
}

.page2-slide-item-open-siblingItem-frame49 {
    aspect-ratio: 1.78 !important;
    // padding: 0px 2px !important;
}

.page2-slide-item-open-siblingItem-frame49-image {
    display: flex !important;
    height: 100% !important;
    padding: 0px 2px !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 10px !important;
    align-self: stretch !important;
    border-radius: 8px !important;
    border: 0.5px solid var(--gray-600, #4A5568) !important;
    background: lightgray 50% / cover no-repeat;
}

.page2-slide-item-open-pos-time {
    display: flex !important;
    align-items: center !important;
    gap: 15px !important;
}

.page2-slide-item-open-pos-time-time {
    display: flex !important;
    width: 127px !important;
    align-items: center !important;
    gap: 5px !important;
}

.page2-slide-item-open-pos-time-time-pos-icon {
    display: flex !important;
    padding: 0px 8px !important;
    align-items: center !important;
    gap: 10px !important;
}

.page2-slide-item-open-pos-time-time-pos-icon-icon {
    width: 24px !important;
    height: 24px !important;
}

.page2-slide-item-open-pos-time-time-text {
    color: var(--black-900, #212121) !important;

    /* lg/medium */
    font-family: "Open Sans" !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 28px !important;
    /* 155.556% */
}

.page2-left-side-image {
    width: calc(100% - 20px) !important;
    border-radius: 8px !important;
    border: 0.5px solid var(--gray-600, #4A5568) !important;
    background: lightgray 50% / cover no-repeat;
    box-shadow: 0px 0px 32.9px 6px rgba(0, 0, 0, 0.15) !important;
}

.page2-left-side-image {
    aspect-ratio: 1.78 !important;
    // padding: 0px 2px !important;
}

Button:hover {
    opacity: 0.9 !important;
}

.page2-save-alert {
    display: flex !important;
    width: 480px !important;
    align-items: flex-start !important;
    border-radius: 8px !important;
    background: #FFF !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 1px 0px rgba(45, 55, 72, 0.05), 0px 4px 8px 0px rgba(45, 55, 72, 0.10) !important;
    border-radius: 8px !important;
    overflow: hidden;
}

.page2-save-alert-icon {
    display: flex !important;
    padding: 8px 20px !important;
    justify-content: center !important;
    align-items: center !important;
    align-self: stretch !important;
    background: #2C7A7B !important;
    color: white !important;
}

.page2-save-alert-icon-icon {
    width: 40px !important;
    height: 40px !important;
}

.page2-frame33 {
    display: flex !important;
    padding: 16px !important;
    align-items: flex-start !important;
    flex: 1 0 0 !important;
}

.page2-frame33-content {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 10px !important;
    flex: 1 0 0 !important;
    background: #FFF !important;
}

.page2-frame31 {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: var(--spacing-xs, 4px) !important;
    align-self: stretch !important;
}

.page2-frame31-text {
    color: #171923 !important;

    /* sm/medium */
    font-family: "Open Sans" !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    /* 142.857% */
}

.page2-frame30 {
    display: flex !important;
    align-items: flex-start !important;
    gap: 12px !important;
}

.page2-frame30-button {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 8px !important;
    border-radius: 8px !important;
    border: 0px solid var(--Colors-Base-white, #FFF) !important;
    color: #319795 !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
}

.page2-frame30-button-text {
    color: #319795 !important;

    /* sm/semibold */
    font-family: "Open Sans" !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    /* 142.857% */
}

.page2-frame30-button:hover {
    cursor: pointer !important;
    opacity: 0.9 !important;
}

.page2-right-side-with-scrollbar {
    display: flex !important;
    width: 35% !important;
    padding: 10px 0px 20px 10px !important;
    align-items: flex-start !important;
    gap: 10px !important;
    align-self: stretch !important;
    background: var(--blackAlpha-100, rgba(0, 0, 0, 0.06)) !important;
    overflow-y: scroll !important;
}

.page2-right-side-with-scrollbar-content {
    display: flex !important;
    width: calc(100% - 20px) !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 17px !important;
    flex-shrink: 0 !important;
}

.page2-header-right-side {
    display: flex !important;
    height: calc(50vh - 240px) !important;
    padding: 20px 25px !important;
    flex-direction: column !important;
    align-items: flex-end !important;
    gap: 10px !important;
    align-self: stretch !important;
}

.page2-before {
    height: calc(100% - 10px) !important;
    width: 100% !important;
    overflow-y: scroll !important;
}

.page2-pos-folie-zurucksetzten {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 10px !important;
    align-self: stretch !important;
}

.page2-pos-folie-zurucksetzten-button {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 8px !important;
    border-radius: 8px !important;
    border: 0px solid var(--Colors-Base-white, #FFF) !important;
}

.page2-pos-folie-zurucksetzten-button-text {
    color: var(--black-900, #212121) !important;

    /* md/normal */
    font-family: "Open Sans" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    /* 150% */
}

.page2-old-folie {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 10px !important;
    align-self: stretch !important;
}

.page2-old-folie-text-zone {
    display: flex !important;
    // max-width: 550px !important;
    padding: 5px 10px 5px 15px !important;
    align-items: center !important;
    gap: 10px !important;
    align-self: stretch !important;
    border-radius: 8px !important;
}

.page2-old-folie-text {
    flex: 1 0 0 !important;
    color: var(--blackAlpha-500, rgba(0, 0, 0, 0.36)) !important;

    /* lg/normal */
    font-family: "Open Sans" !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 28px !important;
    /* 155.556% */
    padding-bottom: 8px !important;
    // border-bottom: #E2E8F0 solid 2px !important;
}

.page2-old-folie-text-zone:hover {
    cursor: pointer !important;
    border-radius: 8px !important;
    background: var(--blackAlpha-50, rgba(0, 0, 0, 0.04)) !important;
}

.page2-header-aktuelle-folie {
    display: flex !important;
    padding-right: 30px !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 15px !important;
    align-self: stretch !important;
}

.page2-pos-folie-zuruck {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-end !important;
    gap: 10px !important;
    align-self: stretch !important;
}

.page2-pos-folie-zuruck-button {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 8px !important;
    border-radius: 8px !important;
    border: 0px solid var(--Colors-Base-white, #FFF) !important;
}

.page2-pos-folie-zuruck-button:hover {
    cursor: pointer !important;
    opacity: 0.9 !important;
}

.page2-pos-folie-zuruck-button-text {
    color: #4A5568 !important;

    /* md/semibold */
    font-family: "Open Sans" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    /* 150% */
}

.page2-zuruck-arrows {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-end !important;
    gap: 10px !important;
    align-self: stretch !important;
}

.page2-zuruck-arrows-buttongroup {
    display: flex !important;
    align-items: flex-start !important;
    border-radius: 5px !important;
    border: 1px solid #E2E8F0 !important;
    background: rgba(0, 0, 0, 0.00) !important;
}

.page2-zuruck-arrows-buttongroup-button {
    display: flex !important;
    width: 40px !important;
    height: 40px !important;
    padding: 7px 8px 9px 8px !important;
    justify-content: center !important;
    align-items: center !important;
    // border-right: 1px solid #E2E8F0 !important;
    // border-left: 1px solid #E2E8F0 !important;
    background: rgba(0, 0, 0, 0.00) !important;
}

.page2-zuruck-arrows-buttongroup-button:hover {
    cursor: pointer !important;
    opacity: 0.9 !important;
}

.page2-zuruck-arrows-buttongroup-button-disabled {
    display: flex !important;
    width: 40px !important;
    height: 40px !important;
    padding: 7px 8px 9px 8px !important;
    justify-content: center !important;
    align-items: center !important;
    // border-radius: 0px 6px 6px 0px !important;
    background: rgba(0, 0, 0, 0.00) !important;
    color: rgba(160, 174, 192, 1);
}

.page2-zuruck-arrows-buttongroup-button-icon {
    width: 24px !important;
    height: 24px !important;
    flex-shrink: 0 !important;
}

.page2-selected-slide {
    display: flex !important;
    min-height: 200px !important;
    flex-direction: column !important;
    align-items: center !important;
    gap: 10px !important;
    align-self: stretch !important;
}

.page2-selected-slide-text {
    display: flex !important;
    max-width: calc(100% - 60px) !important;
    min-width: calc(100% - 60px) !important;
    padding: 10px !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 15px !important;
    border-radius: 8px !important;
    background: var(--white, #FFF) !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
}

.page2-selected-slide-text-content {
    align-self: stretch !important;
    color: var(--black, #000) !important;

    /* lg/normal */
    font-family: "Open Sans" !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 28px !important;
    padding: 8px !important;
    border-radius: 8px !important;
    /* 155.556% */
}

.media-tag-shell {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 10px !important;
    align-self: stretch !important;
}

.media-tag {
    display: flex !important;
    height: 28px !important;
    padding: 0px 3px 0px 8px !important;
    align-items: center !important;
    border-radius: 2px !important;
    background: #319795 !important;
    align-self: flex-start !important;
    width: auto !important;
}

.media-tag:hover {
    cursor: pointer !important;
    opacity: 0.9 !important;
}

.media-tag-icon {
    width: 16px !important;
    height: 16px !important;
}

.media-tag-text {
    display: flex !important;
    padding: 2px 8px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 8px !important;
    color: #FFF !important;
    font-family: "Open Sans" !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 162.5% !important; /* 22.75px */
}

.page2-selected-slide-text-time-pos {
    display: flex !important;
    align-items: center !important;
    gap: 5px !important;
    align-self: stretch !important;
    color: rgba(113, 128, 150, 1)
}

.page2-selected-slide-text-time-shell {
    display: flex !important;
    align-items: center !important;
    gap: 5px !important;
}

.page2-selected-slide-text-time-content {
    color: var(--gray-500, #718096) !important;

    /* lg/medium */
    font-family: "Open Sans" !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 28px !important;
    /* 155.556% */
}

.page2-selected-slide-text-time-icon-pos {
    display: flex !important;
    align-items: center !important;
    gap: 10px !important;
}

.page2-selected-slide-text-time-icon {
    width: 24px !important;
    height: 24px !important;
}

.page2-selected-slide-text-time-pos-badge {
    display: flex !important;
    min-width: 63px !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 10px !important;
    align-self: stretch !important;
}

.page2-selected-slide-text-time-pos-badge-badge {
    display: flex !important;
    padding: 0px var(--spacing-xs, 4px) !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 10px !important;
    align-self: stretch !important;
    border-radius: 2px !important;
    background: var(--gray-500, #718096) !important;
}

.page2-selected-slide-text-time-pos-badge-badge-text {
    color: var(--white, var(--Colors-Base-white, #FFF)) !important;
    font-family: "Open Sans" !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 16px !important;
    /* 133.333% */
    text-transform: uppercase !important;
}