.pptx2video {
    width: 100vw !important;
    height: 100vh !important;
}

.tooltip {
    align-self: stretch !important;
    color: var(--white, var(--Colors-Base-white, #FFF)) !important;
    text-align: center !important;

    /* text-sm/lineHeight-5/font-normal */
    font-family: "Open Sans" !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    /* 142.857% */
}

.zurucksetzen-button {
    display: flex !important;
    padding-bottom: 5px !important;
    flex-direction: column !important;
    justify-content: flex-end !important;
    align-items: center !important;
    align-self: stretch !important;
    gap: 8px !important;
    align-self: stretch !important;
    border-radius: 8px !important;
    border: 0px solid var(--Colors-Base-white, #FFF) !important;
}

.zurucksetzen-button:hover {
    cursor: pointer !important;
    opacity: 0.9 !important;
}

.zurucksetzen-text {
    color: #4A5568 !important;
    font-family: "Open Sans" !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    /* 142.857% */
    text-decoration-line: underline !important;
}


.pptx2video-setting-page {
    display: flex !important;
    padding: 0px 40px 30px 40px !important;
    height: 100% !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 30px !important;
    flex: 1 0 0 !important;
    align-self: stretch !important;
    background: #FFF !important;
}

.content-group {
    display: flex !important;
    align-items: flex-start !important;
    height: calc(100% - 140px) !important;
    gap: 10px !important;
    flex: 1 0 0 !important;
    align-self: stretch !important;
}

.content-left-side {
    display: flex !important;
    width: 876px !important;
    align-items: flex-start !important;
    gap: 10px !important;
    align-self: stretch !important;
    // height: 800px !important;
    overflow-y: scroll !important;
}

.left-side {
    display: flex !important;
    max-width: 850px !important;
    // height: 100% !important;
    padding-top: 20px !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 30px !important;
    flex: 1 0 0 !important;
    padding-left: 2px !important;
}

.voreinstellungen {
    align-self: stretch !important;
    color: #000 !important;

    /* 4xl/medium */
    font-family: "Spline Sans" !important;
    font-size: 36px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 44px !important;
    /* 122.222% */
    letter-spacing: -0.32px !important;
}

.header {
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;
}

.sprache {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 20px !important;
    align-self: stretch !important;
}

.dropdown-sprache {
    display: flex !important;
    width: 333px !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 9px !important;
}

.menu-button {
    display: flex !important;
    height: 40px !important;
    padding: 0px 16px !important;
    justify-content: space-between !important;
    align-items: center !important;
    align-self: stretch !important;
    border-radius: 6px !important;
    border: 1px solid var(--gray-200, #E2E8F0) !important;
}

.MenuButton {
    width: 100% !important;
}

.MenuButton:hover {
    background: var(--gray-100-hover, #EDF2F7) !important;
    opacity: 0.9 !important;
}

.MenuButton-selected {
    width: 100% !important;
    background: var(--gray-100-hover, #EDF2F7) !important;
}

.children {
    color: var(--gray-800, #1A202C) !important;

    /* text-sm/lineHeight-5/font-normal */
    font-family: "Open Sans" !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    /* 142.857% */
}

.right-icon {
    width: 16px !important;
    height: 16px !important;
}

.menu-list {
    display: flex !important;
    width: auto !important;
    padding: 10px !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 10px !important;
    border-radius: 12px !important;
    background: #FFF !important;

    /* sm */
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05) !important;
}

.menu-item {
    display: flex !important;
    padding: 6px 12px !important;
    align-items: center !important;
    gap: 10px !important;
    border-radius: 8px !important;
    background: #FFF !important;
}

.menu-item-selected {
    display: flex !important;
    padding: 6px 12px !important;
    align-items: center !important;
    gap: 10px !important;
    border-radius: 8px !important;
    background: var(--gray-100, #EEF2F6) !important;
}

.menu-item:hover {
    display: flex !important;
    padding: 6px 12px !important;
    align-items: center !important;
    gap: 10px !important;
    border-radius: 8px !important;
    background: var(--gray-100, #EEF2F6) !important;
    opacity: 0.9 !important;
}

.pos-speed-stimme-type {
    display: flex !important;
    align-items: flex-start !important;
    gap: 131px !important;
}

.pos-stimme {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 10px !important;
}

.stimme {
    color: #171923 !important;

    /* lg/medium */
    font-family: "Open Sans" !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 28px !important;
    /* 155.556% */
}

.gender-button-group {
    display: flex !important;
    align-items: flex-start !important;
    border-radius: 5px !important;
    border: 1px solid #E2E8F0 !important;
}

.gender-button {
    display: flex !important;
    padding: 8px 16px !important;
    align-items: flex-start !important;
    gap: 10px !important;
    background: #EDF2F7 !important;
}

.gender-text {
    color: var(--gray-300, #B7B7B7) !important;

    /* md/semibold */
    font-family: "Open Sans" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    /* 150% */
}

.gender-button:hover {
    color: var(--teal-500, #319795) !important;
    /* md/semibold */
    font-family: "Open Sans" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    opacity: 0.9 !important;
    /* 150% */
}

.gender-text-selected {
    color: var(--teal-500, #319795) !important;
    /* md/semibold */
    font-family: "Open Sans" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    /* 150% */
}


.pos-speed {
    display: flex !important;
    width: 290px !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 9px !important;
}

.pos-speed-button-group {
    display: flex !important;
    width: 120px !important;
    align-items: flex-start !important;
    border-radius: 6px !important;
    border: 1px solid #E2E8F0 !important;
    background: rgba(0, 0, 0, 0.00) !important;
}

.pos-speed-button {
    display: flex !important;
    height: 40px !important;
    padding: 7px 8px 9px 8px !important;
    justify-content: center !important;
    align-items: center !important;
    flex: 1 0 0 !important;
    border-radius: 6px !important;
    background: rgba(0, 0, 0, 0.00) !important;
}

.pos-speed-button-selected {
    display: flex !important;
    height: 40px !important;
    padding: 7px 8px 9px 8px !important;
    justify-content: center !important;
    align-items: center !important;
    flex: 1 0 0 !important;
    border-radius: 6px !important;
    // border-right: 1px solid #E2E8F0;
    // border-left: 1px solid #E2E8F0;
    background: #F7FAFC;
}

.pos-speed-button-icon {
    width: 24px !important;
    height: 24px !important;
}

.resolution {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 8px !important;
    align-self: stretch !important;
}

.pos-resolution-button-group {
    display: flex !important;
    align-items: flex-start !important;
    gap: 23px !important;
}

.pos-resolution-button-selected {
    display: flex !important;
    width: 150px !important;
    height: 40px !important;
    padding: 0px 16px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 8px !important;
    border-radius: 8px !important;
    border: 1px solid #319795 !important;
    background: #319795 !important;
}

.pos-resolution-button {
    display: flex !important;
    width: 150px !important;
    height: 40px !important;
    padding: 0px 16px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 8px !important;
    border-radius: 8px !important;
    border: 1px solid #050505 !important;
    background: rgba(0, 0, 0, 0.00) !important;
}

.pos-resolution-text-selected {
    color: var(--Colors-Base-white, #FFF) !important;

    /* md/semibold */
    font-family: "Open Sans" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    /* 150% */
}

.pos-resolution-text {
    color: #4A5568 !important;

    /* md/semibold */
    font-family: "Open Sans" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    /* 150% */
}

.pos-icon-section-header {
    // width: 186px !important;
    height: 28px !important;
    gap: 10px !important;
}

.pos-icon {
    display: inline-flex !important;
    padding: 2px 1px !important;
    align-items: center !important;
    gap: 10px !important;
    cursor: po"Open Sans" !important;
}

.fiInfo-icon {
    width: 24px !important;
    height: 24px !important;
}

.notizen-checkbox {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 10px !important;
    align-self: stretch !important;
}

.notizen-check {
    display: flex !important;
    align-items: center !important;
    // gap: 8px !important;
}

.notizen-check-text {
    color: #171923 !important;

    /* sm/normal */
    font-family: "Open Sans" !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    /* 142.857% */
}

.spezifikationen {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 10px !important;
    align-self: stretch !important;
    overflow: visible !important;
}

.textarea {
    display: flex !important;
    padding: 8px 8px 0px 8px !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 10px !important;
    align-self: stretch !important;
    border-radius: 8px !important;
    border: 1px solid #E2E8F0 !important;
    background: #FFF !important;
    color: #000 !important;
    font-family: "Open Sans" !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    min-height: 60px !important;
    /* 142.857% */
}

.documentation {
    display: flex !important;
    min-height: 314px !important;
    align-items: flex-start !important;
    flex-shrink: 0 !important;
    align-self: stretch !important;
    flex-direction: column !important;
    gap: 10px !important;
    width: 100% !important;
}

.documentation-info {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    align-self: stretch !important;
}

.documentation-info-header {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
}

.documentation-info-header-title {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    align-self: stretch !important;
}

.documentation-info-header-comment {
    align-self: stretch !important;
    color: #000 !important;

    /* md/medium */
    font-family: "Open Sans" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    /* 150% */
}

.documentation-info-header-comment-light {
    color: #000;

    /* md/normal */
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.documentation-upload-button {
    display: flex !important;
    height: 40px !important;
    padding: 0px 16px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 8px !important;
    border-radius: 8px !important;
    background: #319795 !important;
}

.documentation-upload-button-icon {
    width: 16px !important;
    height: 16px !important;
    color: white !important;
}

.documentation-upload-items {
    display: flex !important;
    flex-direction: column !important;
    width: 100%;
}

.documentation-upload-item-shell {
    display: flex !important;
    min-height: 80px !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: flex-start !important;
    gap: 20px !important;
    flex-shrink: 0 !important;
    border-radius: 8px !important;
    border: 0px solid #319795 !important;
    box-shadow: 0px 0px 1px 0px rgba(45, 55, 72, 0.05), 0px 4px 8px 0px rgba(45, 55, 72, 0.10) !important;
}

.documentation-upload-item {
    display: flex !important;
    padding: 16px !important;
    justify-content: space-between !important;
    align-items: center !important;
    align-self: stretch !important;
    border-radius: 8px !important;
    border: 1px solid #E2E8F0 !important;
}

.file-meta-info {
    display: flex !important;
    align-items: center !important;
    gap: 12px !important;
}

.file-icon-box {
    display: flex !important;
    width: 40px !important;
    height: 40px !important;
    padding: 10px !important;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 8px !important;
    background: #F7FAFC !important;
}

.file-icon {
    width: 20px !important;
    height: 20px !important;
    flex-shrink: 0 !important;
}

.file-meta-box {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
}

.file-name {
    color: #171923 !important;

    /* md/medium */
    font-family: "Open Sans" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    /* 150% */
}

.file-size {
    color: #4A5568 !important;

    /* md/normal */
    font-family: "Open Sans" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    /* 150% */
}

.file-options {
    display: flex !important;
    align-items: flex-start !important;
    gap: 12px !important;
}

.file-options-delete {
    display: flex !important;
    height: 40px !important;
    padding: 0px 24px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 8px !important;
    border-radius: 8px !important;
    border: 1px solid #050505 !important;
    background: rgba(0, 0, 0, 0.00) !important;
}

.file-delete-icon {
    width: 16px !important;
    height: 16px !important;
}

.file-options-view {
    display: flex !important;
    height: 40px !important;
    padding: 0px 16px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 8px !important;
    border-radius: 8px !important;
    background: #319795 !important;
}

.file-view-text {
    color: var(--Colors-Base-white, #FFF) !important;

    /* md/semibold */
    font-family: "Open Sans" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    /* 150% */
}

.content-right-side {
    display: flex !important;
    padding: 30px 0px 20px 20px !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 10px !important;
    flex: 1 0 0 !important;
    align-self: stretch !important;
    border-radius: 8px !important;
}

.upload-pptx {
    display: flex !important;
    // height: 350px !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 10px !important;
    align-self: stretch !important;
    border-radius: 8px !important;
    background: #2C7A7B;
}

.upload-pptx {
    aspect-ratio: 1.78 !important;
    // padding: 0px 2px !important;
}

.upload-pptx-done {
    display: flex !important;
    // height: 350px !important;
    padding: 10px 10px 0px 10px !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 20px !important;
    align-self: stretch !important;
    border-radius: 8px !important;
    background: linear-gradient(0deg, rgba(44, 122, 123, 0.20) 0%, rgba(44, 122, 123, 0.20) 100%), lightgray 50% / cover no-repeat;
}

.upload-pptx-done {
    aspect-ratio: 1.78 !important;
    // padding: 0px 2px !important;
}

.progress-with-close {
    display: flex !important;
    align-items: center !important;
    gap: 5px !important;
    height: 24px !important;
}

.progress {
    width: 320px !important;
    height: 12px !important;
    border-radius: 4px !important;
    background: #E6FFFA !important;
}

.progress-close-button {
    display: flex !important;
    align-items: center !important;
    color: white;
}

.progress-close-button:hover {
    cursor: pointer !important;
    opacity: 0.9 !important;
}

.progress-close-button-icon {
    width: 24px !important;
    height: 24px !important;
}

.info-pptx {
    display: flex !important;
    padding: 10px 0px 0px 10px !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    align-self: stretch !important;
    // opacity: 0.4 !important;
}

.hinweis-pptx {
    display: flex !important;
    padding-left: 10px !important;
    flex-direction: column !important;
    justify-content: flex-end !important;
    align-items: flex-start !important;
    flex: 1 0 0 !important;
    align-self: stretch !important;
}

.upload-pptx-button {
    display: flex !important;
    height: 40px !important;
    padding: 0px 16px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 8px !important;
    flex-shrink: 0 !important;
    border-radius: 8px !important;
    background: #319795 !important;
}

.upload-pptx-button-text {
    color: var(--Colors-Base-white, #FFF) !important;

    /* md/semibold */
    font-family: "Open Sans" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    /* 150% */
}

.upload-pptx-button-icon {
    width: 16px !important;
    height: 16px !important;
    color: white !important;
}

.upload-pptx-info-text-head {
    color: var(--black, #000) !important;

    /* xl/semibold */
    font-family: "Open Sans" !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 30px !important;
    /* 150% */
}

.upload-pptx-info-text-content {
    color: var(--black, #000) !important;

    /* xl/normal */
    font-family: "Open Sans" !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 30px !important;
}

.hinweis-text {
    align-self: stretch !important;
    color: var(--black, #000) !important;

    /* sm/normal */
    font-family: "Open Sans" !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    /* 142.857% */
}

.upload-pptx-button-done-close {
    width: 24px !important;
    height: 24px !important;
    flex-shrink: 0 !important;
    color: white !important;
    cursor: pointer !important;
    position: absolute !important;
    top: 40px !important;
    right: 50px !important;
}

.upload-pptx-button-done-shell {
    display: flex !important;
    // padding-top: 100px !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 10px !important;
    align-self: stretch !important;
}

.upload-pptx-button-done {
    display: flex !important;
    height: 40px !important;
    padding: 0px 16px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 8px !important;
    border-radius: 8px !important;
    border: 0px solid var(--Colors-Base-white, #FFF) !important;
    background: #E6FFFA !important;
}

.upload-pptx-button-text-done {
    color: #2C7A7B !important;

    /* md/semibold */
    font-family: "Open Sans" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    /* 150% */
}

.upload-pptx-button-icon-done {
    width: 16px !important;
    height: 16px !important;
    color: #2C7A7B !important;
}

.footer {
    display: flex !important;
    width: 100% !important;
    padding: 20px 30px 20px 0px !important;
    flex-direction: row !important;
    justify-content: flex-end !important;
    align-items: center !important;
    gap: 20px !important;
    border-radius: 8px !important;
    background: #2C7A7B !important;
}

.footer-button {
    display: flex !important;
    width: 210px !important;
    height: 40px !important;
    padding: 0px 16px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 8px !important;
    border-radius: 8px !important;
    background: #319795 !important;
}

.footer-button-cancel {
    display: flex !important;
    height: 40px !important;
    padding: 0px 16px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 8px !important;
    border-radius: 8px !important;
    border: 0px solid #FFF !important;
    background: #E6FFFA !important;
}

.footer-button-text {
    color: var(--Colors-Base-white, #FFF) !important;

    /* md/semibold */
    font-family: "Open Sans" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    /* 150% */
}

Button:hover {
    opacity: 0.9 !important;
}

.running-spinner {
    margin-left: 10px !important;
}

.upload-pptx-info-text-content-uploading {
    background: linear-gradient(to left, #000 0%, #319795 50%, #000 100%);
    background-size: 200% 100%;
    background-position: -100% 0;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: color-shift 1s infinite;
    font-weight: 400;
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
}

.upload-pptx-info-text-content-audio-generating {
    background: linear-gradient(to left, #FFF 0%, #319795 50%, #FFF 100%);
    background-size: 200% 100%;
    background-position: -100% 0;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: color-shift 1s infinite;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
}

@keyframes color-shift {
    0% {
        background-position: 100% 0;
    }

    100% {
        background-position: -100% 0;
    }
}

.uploading {
    background: linear-gradient(to left, #FFF 0%, #319795 50%, #FFF 100%);
    background-size: 200% 100%;
    background-position: -100% 0;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: color-shift 1s infinite;
    font-size: 18px;
    font-weight: 600;
}

.cancel-text {
    color: #2C7A7B !important;

    /* md/semibold */
    font-family: "Open Sans" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    /* 150% */
}