.alert {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin: 0px !important;
    padding: 0px !important;
    border-radius: 10px !important;
    max-width: none !important;
    // width: 100% !important;
    background: rgba(0, 0, 0, 0) !important;
    box-shadow: none !important;
}

.alert-body {
    display: inline-flex !important;
    max-width: 711px !important;
    align-items: center !important;
    background: #FFF !important;
    box-shadow: 0px 0px 1px 0px rgba(45, 55, 72, 0.05), 0px 4px 8px 0px rgba(45, 55, 72, 0.10) !important;
    margin: 0px !important;
    padding: 0px !important;
    border-radius: 10px !important;

    >* {
        flex-grow: 1;
        flex-shrink: 1;
    }

    >*:nth-child(1) {
        flex-grow: 0;
        flex-shrink: 0;
    }

    >*:nth-child(3) {
        flex-grow: 0;
        flex-shrink: 0;
    }
}

.alert-icon-box-warning {
    display: flex !important;
    padding: 20px !important;
    justify-content: center !important;
    align-items: center !important;
    align-self: stretch !important;
    background: var(--yellow-400, #ECC94B) !important;
    border-radius: 8px 0px 0px 8px !important;
}

.alert-icon-box-info {
    display: flex !important;
    padding: 20px !important;
    justify-content: center !important;
    align-items: center !important;
    align-self: stretch !important;
    background: var(--yellow-400, #2C7A7B) !important;
    border-radius: 8px 0px 0px 8px !important;
}

.alert-icon {
    width: 40px !important;
    height: 40px !important;
    color: white !important;
}

.alert-content {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 8px !important;
    align-self: stretch !important;
    background: #FFF !important;
    padding: 16px !important;
}

.alert-content-1 {
    color: #171923 !important;

    /* sm/medium */
    font-family: "Open Sans" !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    /* 142.857% */
}

.alert-content-2 {
    align-self: stretch !important;
    color: #4A5568 !important;

    /* sm/normal */
    font-family: "Open Sans" !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    /* 142.857% */
}

.alert-buttons {
    display: flex !important;
    justify-content: center !important;
    flex-direction: column;
    align-items: center !important;
    align-self: stretch !important;
    background: #FFF !important;
    border-radius: 0px 8px 8px 0px !important;
    border-left: solid !important;
    border-color: #E2E8F0 !important;
    border-width: 1px !important;
}

.alert-top-button-warning {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    align-self: stretch !important;
    padding: 10px !important;
    flex: 1 0 0 !important;
    border-radius: 8px !important;
    border: 0px solid var(--Colors-Base-white, #FFF) !important;
    color: var(--yellow-400, #ECC94B) !important;

    /* sm/semibold */
    font-family: "Open Sans" !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    /* 142.857% */
    border-radius: 0px 8px 0px 0px !important;
    border: none !important;
    border-bottom: solid !important;
    border-color: #E2E8F0 !important;
    border-width: 1px !important;
}

.alert-top-button-info {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    align-self: stretch !important;
    padding: 10px !important;
    flex: 1 0 0 !important;
    border-radius: 8px !important;
    border: 0px solid var(--Colors-Base-white, #FFF) !important;
    color: var(--yellow-400, #2C7A7B) !important;

    /* sm/semibold */
    font-family: "Open Sans" !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    /* 142.857% */
    border-radius: 0px 8px 0px 0px !important;
    border: none !important;
    border-bottom: solid !important;
    border-color: #E2E8F0 !important;
    border-width: 1px !important;
}

.alert-top-button-info:hover, .alert-top-button-warning:hover {
    cursor: pointer !important;
    opacity: 0.9 !important;
}

.alert-bottom-button {
    display: flex !important;
    padding: 10px !important;
    align-items: center !important;
    flex: 1 0 0 !important;
    justify-content: center !important;
    color: #4A5568 !important;

    /* sm/semibold */
    font-family: "Open Sans" !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    /* 142.857% */
    border-radius: 0px 0px 8px 0px !important;
    border: none !important;
}

.alert-bottom-button:hover {
    cursor: pointer !important;
    opacity: 0.9 !important;
}