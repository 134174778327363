.login-left-side {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.heading {
  color: #fff !important;
  /* component-heading-large/3xl */
  //   font-family: Inter !important;
  font-size: 60px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 100% !important;
}

%shared_sub_text {
  color: #fff !important;
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
}

.sub-text {
  @extend %shared_sub_text;
  font-weight: 700;
}

.sub-sub-text {
  @extend %shared_sub_text;
  font-weight: 400;
}

.confirm-email-message {
  color: #4A5568;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.confirm-email-title {
  color: #171923;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.confirm-email-ok {
  color: #319795 !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  padding: 10px;
  height: auto !important;
  min-width: auto !important;
}

.register-form-footer-text {
  color: #4A5568;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.register-container {
  font-family: "Inter" !important;
}

.form-heading {
  color: #171923;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
}

.form-sub-heading {
  color: #4A5568;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.form-sub-headin-link {
  color: #319795;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.signin-btn {
  background: #319795 !important;
}

.signin-btn:disabled {
  background: #319795 !important;
  color: white !important;
}