.alert-generating {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin: 0px !important;
    padding: 0px !important;
    border-radius: 10px !important;
    max-width: none !important;
    // width: 100% !important;
    background: rgba(0, 0, 0, 0) !important;
    box-shadow: none !important;
}

.alert-body-generating {
    display: inline-flex !important;
    width: 496px !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    background: #FFF !important;
    box-shadow: 0px 0px 1px 0px rgba(45, 55, 72, 0.05), 0px 4px 8px 0px rgba(45, 55, 72, 0.10) !important;
    margin: 0px !important;
    padding: 0px !important;
}

.alert-body-generating-product-image-box {
    display: flex !important;
    height: 181px !important;
    justify-content: center !important;
    align-items: center !important;
    align-self: stretch !important;
}

.generating-image {
    width: 496px !important;
    height: 181px !important;
    flex-shrink: 0 !important;
    background: white 50% / cover no-repeat !important;
    object-fit: cover !important;
}

.alert-text-box {
    display: flex !important;
    padding: 32px !important;
    gap: 10px !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    align-self: stretch !important;
}

.alert-content-title {
    align-self: stretch !important;
    color: #171923 !important;

    /* md/medium */
    font-family: "Open Sans" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    /* 150% */
}

.alert-content-content {
    align-self: stretch !important;
    color: #4A5568 !important;

    /* sm/normal */
    font-family: "Open Sans" !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    /* 142.857% */
}

.alert-content-highlight {
    align-self: stretch !important;
    color: #4A5568 !important;

    /* sm/bold */
    font-family: "Open Sans" !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 20px !important;
}